import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { breakpoints, media } from "src/styles/breakpoints"
import parseContent from "src/utils/parseContent"
import SEO from "src/components/SEO"
import { PageWrapper } from "src/layouts/BaseLayout"
import Ad from "src/components/Ad"
import Header from "src/components/Header"
import Container from "src/components/Container"
import SocialMediaSharing from "src/components/SocialMediaSharing"
import {
  ArticleContent,
  TextColumn,
  SharingColumn,
  SideColumn,
} from "src/templates/article"
import Media from "src/components/Media"
import Button from "src/components/Button"
import SectionSlider from "src/components/SectionSlider"
import { PrimaryTitle } from "src/components/Typography"
import { pageNormalizer } from "src/utils/contentNormalizers"
import { get } from "lodash"

export default () => {
  const title = "ArtReview March 2020"
  const path = "/shop/product"

  // check article.js for details of hero_image
  const hero_image = {
    source_url: "https://i.imgur.com/lRqwaOD.jpg",
    caption:
      "Clit Nest, 2019, performance, COS × Serpentine Park Nights, London. Photo: Hugo Glendinning",
  }

  const intro =
    "Featuring Roee Rosen, Wong Ping, Anna Witt, Pedro Neves Marques, Huma Bhabha, a guide to the best exhibitions this month, our regular columns, reviews, books and more"

  const fetchedContent = `<p>The March issue of ArtReview previews at The Armory Show, New York, in the Media Partners section. Copies available on newsstands from Friday</p>
    <h2>Inside</h2>
    <p><i>Laughter in the dark: Roee Rosen</i><br />
    Roee Rosen wants to make us laugh, cry, sweat, well, make us feel anything in fact, to jolt us into the awareness of things we would rather not think about. Across films, texts, performances and paintings, the Tel Aviv-based artist dismantles Jewish identity – replete with painful pasts and contentious presents – ‘and the psychological construct of individual selfhood’, mixing humour, sex and violence to emphasise the fickleness of subjectivity. Ben Eastham speaks with an artist for whom nothing is sacred, and no topic too taboo.</p>
    <p><i>My fake woke confession, by Wong Ping</i><br />
    In a new illustrated project for the pages of the magazine, Wong Ping delivers his woke confession, exposing with a deadpan humour a culture rife with self-shaming, tapping into collective anxieties about not living up to notions of political correctness. Using his signature animation style, the artist punctures the surface of touchy topics such as race, political activism and consent with seething cynicism.</p>
    <p><i>Work and play: Anna Witt</i><br />
    “There are things that robots cannot see… that is our strength” one worker asserts in Anna Witt’s videowork Unboxing the Future, filmed in the Toyota factory in Toyota City and currently showing in Berlin. Mark Rappolt considers how the intervention of technology, AI in particular, will impact the place and function of labour in our lives and society at large.</p>
    <p><i>Ways of being: Pedro Neves Marques</i><br />
    Pedro Neves Marques has created his own ‘brand of speculative fiction tooled to deal with some of most prescient issues of our time, from ecology to body politics’, João Mourão and Luís Silva write. Surveying the films of the Portuguese-born, New York-based artist, the codirectors of Kunsthalle Lissabon disentangle the works’ complex ecosystem that borrows from discourses across biotechnology, sci-fi and queer theory to highlight the violence inherent in our interaction with each other and with nature.</p>
    <h2>Also in the issue</h2>
    <p>In our columns, Christian Viveros-Fauné takes stock of the expansionist trend among New York’s commercial megagalleries in light of Pace’s gargantuan Chelsea HQ, Erika Balsom reflects on meaningful collaborations between artist and subject through the lens of recent work by Steve McQueen and Éric Baudelaire, while Patrick Langley wonders what art and music might do in the face of a looming environmental apocalypse. In his series of interviews, Ross Simonini discusses process, intuition and practicality with Pakistani-American sculptor Huma Bhabha.</p>
    <p>Exhibitions reviews including the inaugural Sharjah Architecture Triennial, the 12th edition of the Bamako Encounters Photography Biennial, Vaivém, a Rio de Janeiro exhibition exploring the cultural meaning of hammocks in indigenous life, an ambitious survey of the work of Derek Jarman at IMMA, Dublin, Cameron Rowland’s first UK solo at the ICA, London, Stan Douglas at Victoria Miro, London, Antony Gormley’s BTS Connect commission on New York’s Brooklyn Bridge Park Pier, Tschabalala Self at ICA Boston, Miriam Cahn at the Museum of Modern Art in Warsaw, Eva Kot’átková at Kunsthal Charlottenborg, Copenhagen, and many more</p>
    <h2>Plus…</h2>
    <p>Martin Herbert’s guide to the must-see exhibitions this month; books including Paul B. Preciado’s An Apartment on Uranus, a collaborative survey of the work of artist and art educator Franz Part, a roundup of new and recent publications on fandom, fetishes and homages; and more</p>`

  const parsedContent = parseContent(fetchedContent)

  return (
    <>
      <SEO title={title} />
      <Ad type="page/before_header" />
      <PageWrapper>
        <Header dark={true} />
        <Container>
          <Title>{title}</Title>
          <Media type="figure" media={hero_image} size="large" />
          <Content>
            <TextColumn>
              <Intro as="h2">{intro}</Intro>
              <Buy>
                <BuyButton label="Buy print edition - £2.99" />
                <BuyButton label="Buy digital edition - £1.99" />
              </Buy>
              <ContentWrapper>
                {parsedContent}
                <SocialMedia title={title} path={path} />
              </ContentWrapper>
            </TextColumn>
            <SharingColumn />
            <SideColumn>
              <Ad type="page/sidebar" />
            </SideColumn>
          </Content>
        </Container>
        <SectionSlider source="stories" title="Most recent" />
      </PageWrapper>
      <Ad type="page/before_footer" />
    </>
  )
}

const Content = styled(ArticleContent)`
  margin-bottom: 130px;
`

const Title = styled(PrimaryTitle)`
  margin-top: 60px;
  margin-bottom: 75px;
  text-align: center;
`

const Intro = styled(PrimaryTitle)`
  margin-bottom: 50px;
  line-height: 36px;
`

const Buy = styled.div`
  margin-bottom: 50px;

  ${media.desktop} {
    display: flex;
  }
`

const BuyButton = styled(Button)`
  width: 100%;
  margin-bottom: 10px;

  ${media.desktop} {
    width: calc(50% - 5px);

    &:last-child {
      margin-left: 10px;
    }
  }
`

const ContentWrapper = styled.div`
  position: relative;
`

const SocialMedia = styled(SocialMediaSharing)`
  position: absolute;
  top: 0;
  left: calc((100% - 30px) / -4 - 10px);

  @media (max-width: ${breakpoints.tablet - 1}px) {
    display: none;
  }
`
